<template>
  <div>
    <!-- header -->
    <MainHeader />
    <!-- main banner -->
    <MainBanner />

    <!-- brand logo -->
    <div class="py-3">
      <div class="container">
        <div id="brand">
          <carousel
            :loop="true"
            :autoplay="true"
            :dots="false"
            :autoplaySpeed="3000"
            :autoplayTimeout="3000"
            :responsive="{
              0: { items: 2, nav: false },
              700: { items: 3, nav: false },
              1000: { items: 4, nav: false },
            }"
          >
            <!--carousel area-->
            <div v-for="(item, i) in brands" :key="i" class="px-1">
              <img :src="item.image" :alt="item.alt" class="rounded-sm" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- our products -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1
          class="pl-2 text-xl font-semibold text-left border-l-4 md:text-2xl border-jkl-orange text-jkl-black"
        >
          OUR <span class="text-jkl-orange">PRODUCTS</span>
        </h1>

        <!-- content -->
        <div class="pt-8 md:flex md:flex-wrap lg:justify-center">
          <div
            v-for="(item, i) in products"
            :key="i"
            class="px-1 pb-2 md:w-1/2 lg:w-1/3"
          >
            <div
              class="p-4 text-gray-800 transition duration-300 ease-linear bg-white hover:text-jkl-orange rounded-xl hover:shadow-xl"
            >
              <img :src="item.image" :alt="item.alt" class="mx-auto" />
              <h1 class="pt-4 text-xl font-semibold uppercase">
                {{ item.alt }}
              </h1>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- our services -->
    <div class="py-10">
      <div class="container">
        <h1
          class="pl-2 text-xl font-semibold text-left border-l-4 md:text-2xl border-jkl-orange text-jkl-black"
        >
          OUR <span class="text-jkl-orange">SERVICES</span>
        </h1>
        <p class="pt-4 text-sm text-left text-gray-600">
          We believe that communication is the key to build for a good business,
          we dedicate to provide the best service to our customers and are known
          for our reliability and service. We also work hard to help our
          customers run smoothly in their business, by providing fast, efficient
          services, such as our one-hour breakdown service to most of the areas.
          <br /><br />
          <span class="font-semibold">Our services include:</span>
        </p>

        <!-- content -->
        <div class="flex flex-wrap pt-6 lg:max-w-3xl lg:mx-auto">
          <div
            v-for="(item, i) in services"
            :key="i"
            class="w-1/2 p-1 md:w-1/4"
          >
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-2 text-sm font-semibold text-jkl-orange">
              {{ item.alt }}
            </h1>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')"
    >
      <div class="container">
        <h1
          class="pl-2 text-xl font-semibold text-left text-white border-l-4 border-jkl-orange"
        >
          WHY <span class="text-jkl-orange">CHOOSE US</span>
        </h1>

        <!-- content -->
        <div class="flex flex-wrap pt-6 lg:max-w-3xl lg:mx-auto">
          <div v-for="(item, i) in choose" :key="i" class="w-1/2 p-2 md:w-1/4">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <h1 class="pt-3 text-sm font-medium leading-tight text-white">
              {{ item.alt }}
            </h1>
          </div>
        </div>
        <!-- cta button -->
        <div class="pt-4">
          <a href="tel:+60331624549">
            <h1
              class="w-32 py-2 mx-auto font-semibold text-white rounded-lg bg-jkl-orange"
            >
              Call Us Now
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <h1
          class="pl-2 text-xl font-semibold text-left border-l-4 border-jkl-orange text-jkl-black"
        >
          ABOUT <span class="text-jkl-orange">US</span>
        </h1>
        <p class="pt-4 text-sm text-left text-gray-600">
          JKL Forklift Equipment Sdn Bhd was established in year 2020 and we are
          fully committed in delivering the best Forklift Services and Solution
          for your, such like Forklift Sales, Forklift Rental, Forklift Parts,
          Forklift Service and Maintenance in Malaysia. We are specialising in
          all the major brands including:
        </p>

        <div class="flex flex-wrap pt-3 md:max-w-xl md:mx-auto">
          <div v-for="(item, i) in logo" :key="i" class="w-1/2 p-2 md:w-1/3">
            <img :src="item" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- enquire form -->
    <div id="element" class="py-10 bg-jkl-orange">
      <div class="container">
        <h1 class="pb-4 text-xl font-semibold text-white md:text-2xl">
           Send An Enquire
        </h1>
        <div class="bg-white h-0.5 w-12 mx-auto"></div>
        <div class="pt-4 text-white">
          <p class="pt-2 text-sm">
            Fill in the form and we'll be in touch with you soon
          </p>
        </div>
        <div id="enquire" class="pt-6 lg:max-w-3xl lg:mx-auto">
          <!-- feedback.activamedia.com.sg script begins here --><iframe
            allowTransparency="true"
            style="min-height: 530px; height: inherit; overflow: auto"
            width="100%"
            id="contactform123"
            name="contactform123"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5661307.html"
            ><p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p></iframe
          ><!-- feedback.activamedia.com.sg script ends here -->
        </div>
      </div>
    </div>

    <!-- footer -->
    <footer class="py-10 bg-gray-100">
      <div class="container">
        <div class="lg:flex lg:flex-wrap lg:items-center">
          <div class="lg:w-1/2">
            <img
              src="/images/main-logo.png"
              alt="JKL FORKLIFT EQUIPMENT SDN BHD"
              class="mx-auto"
            />
            <h1
              class="py-4 text-lg font-semibold leading-tight text-center text-jkl-orange lg:text-xl"
            >
              JKL FORKLIFT EQUIPMENT SDN BHD
            </h1>
            <h1
              class="text-base font-medium leading-tight text-center text-gray-800 "
            >
              No. 03, Cempaka Emas Industrial Park, Jalan Banting, Pandamaran,
              42000 Port Klang, Selangor Darul Ehsan.
            </h1>

            <h1
              class="pt-4 text-base font-medium text-center text-gray-700 lg:text-lg"
            >
              Call Us :
              <a href="tel:+60331624549" class="hover:text-jkl-orange"
                >+603-31624549</a
              >
              |
              <a href="tel:+60166339388" class="hover:text-jkl-orange">
                +6016-633 9388</a
              >
            </h1>
            <h1 class="text-base font-medium text-center text-gray-700 lg:text-lg">E-mail : <a href="mailto:jklforklift20@gmail.com" class="hover:text-jkl-orange">jklforklift20@gmail.com</a></h1>
          </div>
          <div class="pt-5 lg:w-1/2">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4010.2991373564178!2d101.419443!3d2.997561!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x8451a36b2165c290!2sJKL%20Forklift%20Equipment%20Sdn.%20Bhd.!5e1!3m2!1sen!2smy!4v1635394399401!5m2!1sen!2smy"
              width="100%"
              height="300"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </footer>

    <!-- Copyright -->
    <div class="py-3 bg-gray-800">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";

export default {
  name: "Home",
  components: {
    carousel,
    MainHeader,
    MainBanner,
  },
  data() {
    return {
      brands: [
        { image: "/images/brand-1.png", alt: "" },
        { image: "/images/brand-2.png", alt: "" },
        { image: "/images/brand-3.png", alt: "" },
        { image: "/images/brand-4.png", alt: "" },
        { image: "/images/brand-5.png", alt: "" },
        { image: "/images/brand-6.png", alt: "" },
        // { image: "/images/brand-7.png", alt: "" },
      ],
      products: [
        { image: "/images/product-1.jpg", alt: "DIESEL FORKLIFT" },
        { image: "/images/product-2.jpg", alt: "BATTERY FORKLIFT" },
        { image: "/images/product-3.jpg", alt: "GAS FORKLIFT" },
        { image: "/images/product-4.jpg", alt: "POWER STACKER" },
        { image: "/images/product-5.jpg", alt: "REACH TRUCK " },
      ],
      services: [
        { image: "/images/service-1.png", alt: "Forklift Sales & Rent" },
        { image: "/images/service-2.png", alt: "Spare Parts" },
        {
          image: "/images/service-3.png",
          alt: "Mobile On-Site Servicing & Repairs",
        },
        { image: "/images/service-4.png", alt: "Major Workshop Repair" },
      ],
      choose: [
        {
          image: "/images/icon-1.png",
          alt: "One-Hours Breakdown Repair Service",
        },
        { image: "/images/icon-2.png", alt: "More Than 20 Years’ Experiences" },
        { image: "/images/icon-3.png", alt: "Best Selling Price" },
        { image: "/images/icon-4.png", alt: "Quick Response" },
      ],
      logo: [
        "/images/about-1.png",
        "/images/about-2.png",
        "/images/about-3.png",
        "/images/about-4.png",
        "/images/about-5.png",
        "/images/about-6.png",
      ],
    };
  },
};
</script>

<style>
#brand .owl-carousel .owl-stage {
  transition-timing-function: linear !important;
}
#enquire iframe {
  min-height: 550px !important;
}
</style>