<template>
  <div>
    <div class="h-1 bg-jkl-orange"></div>
    <header class="py-4 md:py-4">
      <div class="container">
        <div class="md:flex md:justify-between md:items-center">
          <img
            src="/images/main-logo.png"
            alt="JKL Forklift"
            class="mx-auto md:mx-0"
          />
          <div class="pt-4">
            <h1 class="text-lg font-bold text-jkl-black">
              Call Us :
              <a href="tel:+60166339388"
                ><span
                  class="p-2 transition duration-300 ease-in-out bg-gray-800 rounded-lg text-jkl-orange hover:bg-jkl-orange hover:text-white"
                >
                +6016-633 9388</span
                ></a
              >
            </h1>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>