<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/main-banner.jpg')"
  >
    <div class="container">
      <div class="py-20 md:py-28 lg:py-44">
        <div
          class="py-8 text-white md:pl-5 md:w-3/4 lg:w-2/3 xl:w-1/2 bg-jkl-black bg-opacity-70"
        >
          <h1 class="text-2xl font-semibold md:text-3xl md:text-left">
            ONE STOP
          </h1>
          <h1 class="pb-3 text-3xl font-bold md:text-5xl md:text-left">
            FORKLIFT <span class="text-jkl-orange">SPECIALIST</span>
          </h1>
          <h1
            class="py-2 mx-auto text-base font-semibold border-2 border-white md:mx-0 w-60"
          >
            IN KLANG VALLEY
          </h1>

          <!-- cta-button -->
          <div class="pt-6">
            <a
              href="#"
              id="btn-enquire"
              v-if="!hideEnquiry"
              v-scroll-to="{ el: '#element', duration: 1500 }"
            >
              <h1
                class="py-2 mx-auto text-base font-medium transition duration-300 ease-linear rounded-lg md:mx-0 w-36 hover:bg-opacity-80 bg-jkl-orange"
              >
                Enquire Now
              </h1>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>